import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom'
import { country_code_array2 } from '../functions';
import "react-datepicker/dist/react-datepicker.css";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";


import '../../../My_Account.css'
import Sidebar from './Sidebar';
import { Helmet } from 'react-helmet';

const labelstyle = {
    color: "#000 !important",
    fontSize: "13px !important"
}
export default function Document_quote() {

    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const navigate=useNavigate()
    const [countdiv, setcountdiv] = useState(1)
    const [countdiv_domestic, setcountdiv_domestic] = useState(1)
    function increment() {
        setcountdiv((prevCount) => prevCount + 1);


    }
    const [countries, setcountries] = useState(country_code_array2);
    const date=new Date();
    const today= date.getDay()
 
     const [daycount, setdaycount] = useState(0)
     const [startDate, setStartDate] =useState(today==6?new Date(date.setDate(date.getDate()+2)):new Date(date.setDate(date.getDate()+1)))
    
     const date2=new Date();
     const today2= date2.getDay()
      const [startDate2, setStartDate2] =useState(today2==6?new Date(date2.setDate(date2.getDate()+2)):new Date(date2.setDate(date2.getDate()+1)))
     
      const isWeekday = (date) => {
          const day = date.getDay();
          return day !== 0 ;
        };

    const [shiptype, setshiptype] = useState('export')
    const [exportclass, setexportclass] = useState('active')
    const [importclass, setimportclass] = useState('')

    function showthisdiv(val) {
        if (val == 'exportdiv') {
            
            setshiptype('export')
            setexportclass('active')
            setimportclass('')
           // setgetQuotedata({...getQuotedata,shipmenttype:val})
            setgetQuotedata({...getQuotedata,shipmenttype:val,origin:'AE',destination:'GB'})
        } else if (val == 'importdiv') {
           
            setshiptype('import')
            setexportclass('')
            setimportclass('active')
            //setgetQuotedata({...getQuotedata,shipmenttype:val})
            setgetQuotedata({...getQuotedata,shipmenttype:val,origin:'GB',destination:'AE'})
           
        }
       
        
    }
    
    const user=sessionStorage.getItem('userid');
    const [getQuotedata, setgetQuotedata] = useState(
        {
            user:user,
            origin:'AE',
            destination:'GB',
            shipmenttype:"exportdiv",
            typediv:"document",
            shipping_date:startDate,
            dimensions:{
                weight:['0.5'],
                length:[],
                width:[],
                height:[]
    
               }
        
    
        }
    )

    function updateweight(val,i){
        const updatedCount = 1;
        const clonedListing = {...getQuotedata.dimensions};
        const updatedAccepted = [...clonedListing.weight]
        updatedAccepted[i] = val;
        setgetQuotedata({...getQuotedata,
            dimensions: {
              ...clonedListing,
              weight: updatedAccepted
            }
          });
    
    }
    
    function updatelength(val,i){
        const updatedCount = 1;
        const clonedListing = {...getQuotedata.dimensions};
        const updatedAccepted = [...clonedListing.length]
        updatedAccepted[i] = val;
        setgetQuotedata({...getQuotedata,
            dimensions: {
              ...clonedListing,
              length: updatedAccepted
            }
          });
    
    }
    
    function updatewidth(val,i){
        const updatedCount = 1;
        const clonedListing = {...getQuotedata.dimensions};
        const updatedAccepted = [...clonedListing.width]
        updatedAccepted[i] = val;
        setgetQuotedata({...getQuotedata,
            dimensions: {
              ...clonedListing,
              width: updatedAccepted
            }
          });
    
    }
    
    function updateheight(val,i,){
        const updatedCount = 1;
        const clonedListing = {...getQuotedata.dimensions};
        const updatedAccepted = [...clonedListing.height]
        updatedAccepted[i] = val;
        setgetQuotedata({...getQuotedata,
            dimensions: {
              ...clonedListing,
              height: updatedAccepted
            }
          });
    
    }

    function changedate(date){
        setStartDate(date)
        setgetQuotedata({...getQuotedata,shipping_date:date})
    }
    const API_PATH=`${SERVER_URL}quote.php`;
    function getQuote(){
        axios({
            method: 'post',
            url: `${API_PATH}`,
            data: getQuotedata
          })
          .then((res)=>{
           
              sessionStorage.setItem('qouteinput',JSON.stringify(getQuotedata))
    navigate('/quotation',{state:{basic:res.data.basic,qoute_rate:res.data.rate,get_gov_vat:res.data.charge1,charge_rate:res.data.charge_rate,es_surcharge:res.data.es_surcharge,oversize:res.data.oversize,er_charge:res.data.er_charge,rd_charge:res.data.RD_charge,user_discount:res.data.user_discount,user_text:res.data.user_text,weight:res.data.weight,chargable_weight:res.data.chargable_weight,get_transit_time:res.data.get_transit_time}})
             
          }).catch(error => console.log(error.message));
       
        }

        function removemore(val, shiptype) {
            if (shiptype == 'inter') {
    
                setcountdiv((prevCount) => prevCount - 1);
                const clonedListing = { ...getQuotedata.dimensions };
                const updatedAcceptedweight = [...clonedListing.weight]
                const updatedAcceptedlength = [...clonedListing.length]
                const updatedAcceptedwidth = [...clonedListing.width]
                const updatedAcceptedheight = [...clonedListing.height]
                updatedAcceptedweight.splice(val, 1);
                updatedAcceptedlength.splice(val, 1);
                updatedAcceptedwidth.splice(val, 1);
                updatedAcceptedheight.splice(val, 1);
                setgetQuotedata({
                    ...getQuotedata,
                    dimensions: {
                        ...clonedListing,
                        height: updatedAcceptedheight,
                        length: updatedAcceptedlength,
                        width: updatedAcceptedwidth,
                        weight: updatedAcceptedweight,
                    }
                });
            }
        }
        function copySec(val) {
            const clonedListing = { ...getQuotedata.dimensions };
            const updatedAcceptedweight = [...clonedListing.weight]
            const updatedAcceptedlength = [...clonedListing.length]
            const updatedAcceptedwidth = [...clonedListing.width]
            const updatedAcceptedheight = [...clonedListing.height]
            updatedAcceptedweight[countdiv] = updatedAcceptedweight[val];
            updatedAcceptedlength[countdiv] = updatedAcceptedlength[val];
            updatedAcceptedwidth[countdiv] = updatedAcceptedwidth[val];
            updatedAcceptedheight[countdiv] = updatedAcceptedheight[val];
            setgetQuotedata({
                ...getQuotedata,
                dimensions: {
                    ...clonedListing,
                    weight: updatedAcceptedweight,
                    length: updatedAcceptedlength,
                    width: updatedAcceptedwidth,
                    height: updatedAcceptedheight
                }
            });
            setcountdiv((prevCount) => prevCount + 1);
        }
    return (
        <div className="my_parcels">
            <Helmet>
        <title>Time Express | Document Quote</title>
      </Helmet>
            <div className="container myparctab" >

                <div className="container-fluid my_quote">
                    <div className="row">

                        <Sidebar />
                        <div id="admin-main-control" className="col-md-9 p-x-3 p-y-1">

                            <form method="post" action="<?php echo get_the_permalink(193); ?>" id="send_parcels">
                                <div className="col-md-12 col-sm-12 yodeldirect" id="quote_get">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 send_a_parcel">
                                            <h3 className="comaprison_hedding">QUOTE DOCUMENT</h3>
                                            <div id="exTab1" style={{ border: " 1px solid #d85b12" }}>
                                                <ul className="nav nav-pills">
                                                    <li className="active"><a onClick={() => showthisdiv('exportdiv')} className="import_export" flag="export" id="export-link">Export</a></li>
                                                    <li><a onClick={() => showthisdiv('importdiv')} className="import_export" flag="import" id="import-link">Import</a></li>
                                                </ul>

                                                {shiptype && shiptype == 'export'?(
                                                    <div className="tab-content clearfix quotetab">
                                                        <div className="tab-pane " id="1a" style={{ background: "#fff !important", display: "block" }}>
                                                            <h3 className="section-head" style={{ fontSize: "16px", color: "#4b609b", fontWeight: "500", marginTop: "2%" }}>Where are you sending your parcels from/to?</h3>
                                                            <div className="tab-content">
                                                                <div id="export" className="export-content">
                                                                    {/*?php  
                                                $codeArray = country_code_array();
                                                asort($codeArray);
                                                ?*/}
                                                                    <ul className="business_region quotesec2 export-area">
                                                                        <li>
                                                                            <label style={{ color: "#000 !important" }}>From</label>
                                                                            <select id="export_from" className="form-control export-field from_country delivery_from" name="delivery_from" onChange={(e)=>setgetQuotedata({...getQuotedata,origin:e.target.value})}>
                                                                                <option value="AE">Abu Dhabi</option>
                                                                                <option value="AE">Ajman</option>
                                                                                <option value="AE">Al Ain</option>
                                                                                <option value="AE" selected>Dubai</option>
                                                                                <option value="AE">Fujairah</option>
                                                                                <option value="AE">Ras Al Khaimah</option>
                                                                                <option value="AE">Sharjah</option>
                                                                                <option value="AE">Umm Al Quwain</option>
                                                                            </select>
                                                                        </li>
                                                                        <li>
                                                                            <label >To</label>
                                                                            <select id="export_to" className="form-control export-field import-field-to to_country delivery_to" name="delivery_to" onChange={(e)=>setgetQuotedata({...getQuotedata,destination:e.target.value})}>

                                                                                {

                                                                                    Object.entries(countries).map(([k, v]) => (
                                                                                        <option key={k} value={k} selected={k === 'GB'}>{v}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <input type="hidden" name="ip_country" defaultValue className="ip_country" />
                                                                        </li>
                                                                        <p className="error" />
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {shiptype && shiptype == 'import' ? (
                                                    <div className="tab-content clearfix quotetab">
                                                        <div className="tab-pane1" id="2a">
                                                            <h3 className="section-head" style={{ fontSize: "16px", color: "#4b609b", fontWeight: "500", marginTop: "2%" }}>Where are you sending your parcels from/to?</h3>
                                                            <div className="tab-content">

                                                                <ul className="business_region quotesec2 export-area">
                                                                    <li>
                                                                        <label>From</label>
                                                                        <select id="import_from" className="form-control import-field import-field-from to_country delivery_from" name="delivery_from" onChange={(e)=>setgetQuotedata({...getQuotedata,origin:e.target.value})} >

                                                                            {

                                                                                Object.entries(countries).map(([k, v]) => (
                                                                                    <option key={k} value={k} selected={k === 'GB'}>{v}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </li>
                                                                    <li>
                                                                        <label>To</label>
                                                                        <select id="import_to" className="form-control import-field from_country delivery_to" name="delivery_to"  onChange={(e)=>setgetQuotedata({...getQuotedata,destination:e.target.value})}>
                                                                            <option value="AE">Abu Dhabi</option>
                                                                            <option value="AE">Ajman</option>
                                                                            <option value="AE">Al Ain</option>
                                                                            <option value="AE" selected>Dubai</option>
                                                                            <option value="AE">Fujairah</option>
                                                                            <option value="AE">Ras Al Khaimah</option>
                                                                            <option value="AE">Sharjah</option>
                                                                            <option value="AE">Umm Al Quwain</option>
                                                                        </select>
                                                                        <input type="hidden" name="ip_country" defaultValue className="ip_country" />
                                                                    </li>
                                                                    <p className="error" />
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 send_a_parcel send_weight quotetab">
                                        <div className="add_more_weight">
                                            {[...Array(countdiv)].map((e, i) => <><div key={i}>
                                                <h3 className="section-head" style={{ fontSize: "16px", color: "#4b609b", fontWeight: "500", marginTop: "2%" }}>Enter the weight and dimensions of your parcels {i > 0 ? (
                                                                    <button type="button" className="removebtn" onClick={() => removemore(i, 'inter')}>x</button>
                                                                ) : null}</h3>
                                                <ul className="business_region">

                                                    <li style={{ width: "24%" }}>
                                                        <label className="label--reg">Weight<span className="required">*</span> (kg)</label>
                                                        <select className="form-control weight-class weight-class-first" name="weight[]" onChange={(e)=>updateweight(e.target.value,i)}>

                                                        {i>0?(
                           <option value="">select weight</option>
                           ):null
                         }
                                       <option value="0.5" selected={getQuotedata.dimensions.weight[i] === '0.5'}>0.5</option>
                                                                                <option value="1" selected={getQuotedata.dimensions.weight[i] === '1'}>1</option>
                                                                                <option value="1.5" selected={getQuotedata.dimensions.weight[i] === '1.5'}>1.5</option>
                                                                                <option value="2" selected={getQuotedata.dimensions.weight[i] === '2'}>2</option>
                                                                         

                                                        </select>
                                                    </li>
                                                    <li style={{ width: "24%", marginRight: "1%" }}>
                                                        <label className="label--reg">Length (cm)</label>
                                                        <input className="input--small" name="length[]" value={getQuotedata.dimensions.length[i]} placeholder type="text" onChange={(e)=>updatelength(e.target.value,i)}/>
                                                    </li>
                                                    <li style={{ width: "24%", marginRight: "1%" }}>
                                                        <label className="label--reg">Width (cm)</label>
                                                        <input className="input--small required" value={getQuotedata.dimensions.width[i]} name="width[]" placeholder type="text" onChange={(e)=>updatewidth(e.target.value,i)} />
                                                    </li>
                                                    <li style={{ width: "24%", marginRight: "1%" }}>
                                                        <label className="label--reg">Height (cm)</label>
                                                        <input className="input--small" value={getQuotedata.dimensions.height[i]} name="height[]" placeholder type="text" onChange={(e)=>updateheight(e.target.value,i)} />
                                                    </li>
                                                    <a className="copybtn" onClick={() => copySec(i)}>copy</a>
                                                </ul>
                                            </div>
                                            </>
                                            )}
                                        </div>



                                        <div className="cross_Another_sec">+
                                            <button className="btn btn-parcels noradius" id="add_more_sizes" type="button" onClick={increment} style={{ color: "#2c4079", borderBottom: "1px solid" }}>
                                                <div className="center-con cross_icons">
                                                    <div className="round">
                                                        <span />
                                                        <span />
                                                        <span />
                                                        <span />
                                                    </div>
                                                </div>
                                                Add a different size
                                            </button>
                                        </div>
                                    </div>
                                    <hr className="hr_border" />
                                    <div className="col-md-10 col-sm-10 parcel_date quotetab">
                                        <h3 className="section-head" style={{ fontSize: "16px", color: "#4b609b", fontWeight: "500", marginTop: "2%" }}>When are you shipping?</h3>
                                        <DatePicker className="form-control shipping_date" filterDate={isWeekday} minDate={startDate2}  name="shipping_date" dateFormat='dd/MM/yyyy' selected={startDate} 
                                        onChange={date => {
                                            changedate(date);
                                        }} />

                                    </div>
                                </div>
                                <hr className="hr_border" />
                                <div className="wrape_promo">

                                    <span className="get_center_bnts">
                                        <label className>&nbsp;</label>
                                        <button className="btn_same1" type="button" name="get_quotes" id="get_quote" onClick={getQuote}>SUBMIT</button>
                                        <input type="hidden" name="get_quote" defaultValue="Get Quote" />

                                        <input type="hidden" name="parcel_type" id="parcel_type" defaultValue="export" />
                                        <input type="hidden" name="promo_discount" defaultValue id="promo_discount" />
                                    </span>
                                </div>


                            </form>

                        </div>


                    </div> {/* /.row */}
                </div> {/* /.container-fluid */}
            </div>
        </div>
    )
}


